<template>
  <div class="public-scroll-transparent public-page-style">
    <div class="body">
      <div class="userTree">
        <div class="title"></div>
        <div class="treeBox">
          <el-cascader :options="options" :props="props1" v-model="selectUserId" filterable clearable style="width:100%"
            @change="getChildProjectGroupTree">
          </el-cascader>
          <!-- <el-select
          filterable 
            style="width: 100%"
            v-model="selectUserId"
            @change="getChildProjectGroupTree"
            placeholder="请选择"
          >
            <el-option
              v-for="item in projectGroupChangeArr"
              :key="item.userId"
              :label="item.fullName"
              :value="item.userId"
            >
            </el-option>
          </el-select> -->
      <div class="down-tree">
        <el-tree
            ref="tree"
            node-key="id"
            :default-expanded-keys="expanded"
            :highlight-current="true"
            :data="treeData"
            :props="props"
            @node-click="handleNodeClick"
          ></el-tree>
      </div>
        </div>
      </div>
      <div class="historyBox">
        <div class="historyTitle">
          <el-form :inline="true" :model="historyFrom" class="iot-form-inline">
            <el-form-item label="日期">
              <el-date-picker
              size="mini"
              :clearable="false"
                v-model="historyFrom.date"
                type="date"
                @change="paramChange"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="参数">
              <el-select
              size="mini"
                multiple
                collapse-tags
                :multiple-limit="5"
                v-model="historyFrom.paramId"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in paramList"
                  :key="
                    item.paramId +
                    '/' +
                    item.gatewayId +
                    '/' +
                    item.subOriginalId
                  "
                  :label="item.paramName"
                  :value="
                    item.paramId +
                    '/' +
                    item.gatewayId +
                    '/' +
                    item.subOriginalId
                  "
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
              size="mini"
                type="success"
                icon="el-icon-search"
                @click="paramChange"
              ></el-button>
            </el-form-item>
          </el-form>

          <div class="switch">
            <div @click="historyCheck" :class="{highlight:checkHistory}">
              <el-dropdown class="ysList">
                 <img src="../../public/backImg/historyLogo.png" alt="" />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    >
                    历史数据折线图
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
             
            </div >
            <div @click="dataListCheck" :class="{highlight:!checkHistory}">
                <el-dropdown class="ysList">
                 <img src="../../public/backImg/formLogo.png" alt="" />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    >
                    历史数据表格
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              
            </div>
            <div @click="downpopShow=true">
               <el-dropdown class="ysList">
                 <img src="../../public/backImg/download.png" alt="" />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    >
                    历史数据下载
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              
            </div>
          </div>
        </div>

        <el-dialog
  title="选择时间"
  :visible.sync="downpopShow"
  width="30%"
  >
  <el-form  label-width="100px">
    <el-form-item label="文件名称：">
      <el-input 
      clearable
        v-model="projectName" 
        placeholder="请输入文件名称" 
        size="small" 
        style="width:300px"
        @input="projectName = projectName.replace(/[^a-zA-Z0-9\u4e00-\u9fa5]/g, '')"
      ></el-input>
    </el-form-item>
    <el-form-item label="日期选择：">
      <el-date-picker
            :editable="false"
            size="small"
            v-model="downLodaDate"
            type="daterange"
            range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :picker-options="pickerOptions1"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
    </el-form-item>
  </el-form>
  <span slot="footer" class="dialog-footer">
    <el-button @click="downpopShow = false,projectName=''" size="mini">取 消</el-button>
    <el-button type="primary" @click="downpopShow = false,downloadData()" size="mini">确 定</el-button>
  </span>
</el-dialog>
       <!-- 折线图 -->
        <div class="historyBody" v-show="checkHistory">
          <div id="historyData" ref="historyData" 
          :style="`width: 1250px; height:775px;zoom:${zoom};transform:scale(${1/zoom});transform-origin:0 0`"></div>
        </div>
        <!-- 表格 -->
        <div class="historyBody" v-show="!checkHistory">
          <div class="tableList">
            <div
              style="width: 240px; flex-grow: 0; margin: 0 7px"
              v-for="(item1, index) in historyData"
              :key="index"
            >
              <el-table :data="item1.stream" max-height="700">
                <el-table-column
                  :label="item1.name"
                  :header-row-style="titleStyle"
                  width="220"
                >
                  <el-table-column  label="采集时间" width="110">
                    <template slot-scope="scope">{{Object.keys(scope.row)[0]}}</template>
                  </el-table-column>
                  <el-table-column  label="数值" width="110">
                    <template slot-scope="scope">{{Object.values(scope.row)[0]}}</template>
                  </el-table-column>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import debounce from 'lodash/debounce';
import * as echarts from "echarts";
let that;

export default {
  name: "History",
  
  data: function () {
    return {
      projectName:'',
      downpopShow:false,
      downLodaDate:[],
      pickerOptions1: {
        onPick: ({ maxDate, minDate }) => {
          this.pickerMinDate = minDate.getTime();
          if (maxDate) {
            this.pickerMinDate = "";
          }
        },
        disabledDate: (time) => {
          if (this.pickerMinDate !== "") {
            const day30 = (31 - 1) * 24 * 3600 * 1000;
            // 只选后30天，minTime不用加day30,只选前30天，maxTime不用加day30
            let maxTime = this.pickerMinDate + day30;
            let minTime = this.pickerMinDate - day30;
            if (maxTime > new Date()) {
              maxTime = new Date();
            }
            return time.getTime() > maxTime || time.getTime() < minTime;
          }
          return time.getTime() > Date.now();
        },
      },
      options:[],
      props1: {
          lazy: true,
          checkStrictly: true,
          emitPath: false,
          lazyLoad(node, resolve) {
            that.getChildProjectGroupTree(node.value)
            return that.getlazyLoad(node, resolve)
          }
        },
      zoom:1,
      expanded:[],
      checkHistory: true,
      selectUserId: "",
      projectGroupChangeArr: "",
      treeData: [],
      props: {},
      paramList: {},
      historyFrom: {
        projectId: "",
        paramId: [],
        date: "",
      },
      historyData: [],
      tableData: [],
      historyDate: "",
      //设置时间
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      getHistory(data, date, ref, loading) {
        loading.close();
        data.forEach((item) => {
          if (item.data && item.data.length > 1) {
          let arrData=[]
          item.data.forEach((ite) => {
          let newArr = []
          for(let key in ite) {
      newArr.push(key, ite[key])
      }
            arrData.push(newArr)
      })
      this.$set(item, 'data', arrData)
        }
        })
        let option = {
          tooltip: {
            trigger: "axis",      
          },
          toolbox: {
            // feature: {
            //   saveAsImage: {},
            // },
            right: 20,
            top: 20,
          },
          legend: {
            right: 0,
            top: "50%",
            orient: "vertical",
          },
          grid: {
            y: 50,
            y2: 100,
            x: 100,
            x2: 100,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: date,
          },
          yAxis: {
            type: "value",
            nameLocation: "end",
            boundaryGap: [0, 0],
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          series: data,
        };
        var chartDom = document.getElementById(ref);
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
       
      },
    };
  },
  mounted() {
    this.zoom = 1 / document.body.style.zoom;
    window.addEventListener('resize', () => {
      this.zoom = 1 / document.body.style.zoom;
    })
    that.getChildProjectGroupNoPage();
    that.getDate();
  },
  methods: {
    getlazyLoad(node, resolve) {
        const { level } = node;
        let type = level == 0 ? that.$store.state.userId : node.value
        const nodes = []
        // if (level == 1 && type == that.$store.state.userId) {
        //   node.loading = false
        //   node.loaded=true
        //   return
        // }
      that.$api.projectGroup.searchUserChild(type).then((res) => {
          if (res.code == 200) {
            node.loading = false
            node.loaded = true
            if (res.data.length > 0) {
              res.data.map((item) => {
                let obj = {
                  value: item.userId,
                  label: item.fullName,
                  leaf: !item.children
                }
                nodes.push(obj)
              })
              if (level == 0) {
                nodes.unshift({ value: that.$store.state.userId, label: that.$store.state.name, leaf: true })
                that.options = nodes
              }
            }
            resolve(nodes);
          }
        })
      },
    titleStyle() {
      return "text-align:center;";
    },
    historyCheck() {
      that.checkHistory = true;
      that.setHisturyBody();
    },
    dataListCheck() {
      that.checkHistory = false;
      that.setHisturyBody();
    },
    setHisturyBody() {
      if (that.checkHistory) {
        that.setHistory();
      }
    },
    paramChange: debounce(function () {
      if (that.historyFrom.paramId.length > 0) {
        let subOriginalId = '';
        let paramIds=[]
        that.paramList.forEach((item) => {
          that.historyFrom.paramId.forEach((ite) => {
            if (
              item.paramId == ite.split("/")[0] &&
              item.gatewayId == ite.split("/")[1] &&
              item.subOriginalId == ite.split("/")[2]
            ) {
              subOriginalId=item.subOriginalId
              paramIds.push(item.paramId,);
            }
          });
        });
        let data = {
          projectId: that.historyFrom.projectId,
          date: that.historyFrom.date,
          ctrls: [
            {
              ctrlId: 0,
              subOriginalId,
              paramIds
            },
          ],
        };

        that.getHistoryData(data);
      } 
    },300),
    //获取历史曲线数据
    getHistoryData(data) {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      that.$api.largeDataScreen.getHistoryData(data).then((res) => {
        loading.close();
        if (res.code == 200) {
          that.historyData = res.data[0].data;
          that.historyDate = res.data[0].date;
          that.setHisturyBody();
        }
      });
    },
    setHistory() {
      let arr = [];
      const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      this.historyData.forEach((element) => {
        // element.data.forEach(item=>{
        //   item[1]=`${item[1]}`
        // })
        // console.log(element.data);
        arr.push({
          name: element.name,
          type: "line",
          smooth: true,
          symbol: 'emptyCircle',
          data: element.stream,
          connectNulls: true,
        });
      });
      that.getHistory(arr, that.historyDate, "historyData",loading);
      // document.getElementById("historyData").style.zoom=document.body.scrollHeight/969;
    },
    //获取非布尔参数列表
    getParamList(id) {
      that.$api.parameter.getParamList(id).then((res) => {
        if (res.code == 200) {
          that.paramList = res.data;
          if (res.data.length > 0) {
            that.historyFrom.paramId = [
            that.paramList[0].paramId +
              "/" +
              that.paramList[0].gatewayId +
              "/" +
              that.paramList[0].subOriginalId,
          ];
          } else {
         that.historyFrom.paramId=[]
        }
          that.paramChange();
        }
      });
    },
    //获取布尔参数列表
    // getParamBoolList(id) {
    //   that.$api.parameter.getParamBoolList(id).then((res) => {
    //     if (res.code == 200) {
    //       if (res.data.length>0) {
    //         that.paramList.push(res.data);
    //       }

    //       
    //     }
    //   });
    // },
    //历史数据下载
    downloadData() {
      if (that.historyFrom.paramId.length > 0) {
        let subOriginalId = '';
        let paramIds=[]
        that.paramList.forEach((item) => {
          that.historyFrom.paramId.forEach((ite) => {
            if (
              item.paramId == ite.split("/")[0] &&
              item.gatewayId == ite.split("/")[1] &&
              item.subOriginalId == ite.split("/")[2]
            ) {
              subOriginalId=item.subOriginalId
              paramIds.push(item.paramId,);
            }
          });
        });
        let data = {
          projectId: that.historyFrom.projectId,
          startDate:this.downLodaDate[0],
          endDate:this.downLodaDate[1],
          projectName:this.projectName,
          subOriginalId,
          paramIds
        };
     const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });

        that.$api.downLoad.getDownLoad(data).then((res) => {
          loading.close();
          if (res.code == 200) {
           this.$message.success('下载成功，请到下载管理中查看！')
          }
        });
      }
    },

    //   获取用户列表
    getChildProjectGroupNoPage: function () {
      that.selectUserId = window.localStorage.getItem("cId") * 1;
      that.getChildProjectGroupTree(that.selectUserId);
      // that.$api.projectGroup.getChildUserNoPage().then((response) => {
      //   if (200 == response.code) {
      //     that.projectGroupChangeArr = response.data;
      //     that.selectUserId = that.projectGroupChangeArr[0].userId;
      //   }
      // });
    },
    // 获取项目树
    getChildProjectGroupTree: function (userId) {
      document.querySelectorAll(".el-cascader-node__label").forEach(el => {
          el.onclick = function () {
            if (this.previousElementSibling) this.previousElementSibling.click();
          };
      });
      if (!userId) return
      that.$api.projectGroup
        .getChildProjectGroupTree(userId)
        .then((response) => {
          if (200 == response.code && response.data[0]) {
            let tree = [];
            response.data.forEach((element) => {
              let ele = [];
              element.children.forEach((item) => {
                ele.push({
                  id: item.projectId,
                  label: item.projectName,
                  planId: item.planId,
                });
              });
              tree.push({
                id: element.projectGroupId,
                label: element.projectGroupName,
                children: ele,
              });
            });
            that.treeData = tree;
            if (response.data[0].children && response.data[0].children.length>0) {
              that.historyFrom.projectId =
                response.data[0].children[0].projectId;
              that.getParamList(response.data[0].children[0].planId);
              that.expanded=[that.treeData[0].id];
              
              this.$refs.tree.setCheckedKeys([
                response.data[0].children[0].projectId,
              ]);
            } else {
              that.historyFrom.paramId=
              that.paramList = []
              that.historyData=[]
              that.historyDate=[]
            }
          }
        });
    },
    //树状结构点击事件
    handleNodeClick: function (data, node) {
      if (data.planId) {
        this.$refs.tree.setCheckedKeys([data.id]);
        that.getDate();
        that.getParamList(data.planId);
        that.historyFrom.projectId = data.id;
      }
    },
    getDate() {
      let date = new Date();
      let month=(date.getMonth() + 1)<10?'0'+(date.getMonth() + 1):(date.getMonth() + 1);
      let dates=date.getDate()<10?('0'+date.getDate()):date.getDate();
 
      that.historyFrom.date =
        date.getFullYear() + "-" + month + "-" + dates;
       
    },
  },
  created() {
    that = this;
    this.downLodaDate=[this.dayjs().format('YYYY-MM-DD'),this.dayjs().format('YYYY-MM-DD')]
  },
};
</script>

<style scoped>
.body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
}
.body > div {
  flex-shrink: 0;
  flex-grow: 0;
  box-sizing: border-box;
}
.userTree {
  width: 20%;
  padding: 10px 20px;
  
  box-shadow: 0 2px 12px 0 #dfdfdf;
}
.historyBox {
  padding: 10px 30px;
  width: 80%;
  height: 100%;
}
.historyTitle {
  width: 100%;
  margin: 20px 0;
  box-sizing: border-box;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.switch {
  width: 110px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  margin-right: 90px;
  align-items: center;
  overflow: hidden;
  
}
.switch div {
  width: 30px;
  height: 25px;
  box-sizing: border-box;
  cursor: pointer;
}
.switch div img {
  height: 100%;
}
.check {
  background: transparent !important;
}
.historyBody {
  width: 100%;
  height: 92%;
  box-shadow: 0 2px 12px 0 #dfdfdf;
  padding: 10px 0;
}
.historyBody .tableList {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
}
#historyData {
  width: 1250px;
  height: 775px;
}
::v-deep .is-checked {
  color: #409eff !important;
}
::v-deep .el-table__header {
  width: 100% !important;
}
.table {
  width: 300px !important;
}
::v-deep .cell {
  text-align: center !important;
}
.treeBox{
  margin-top:20px;
  height: 80%;
}
.public-page-style{
  height: 100%;
}
::v-deep .el-form-item{
  margin: 0 !important;
  margin-right: 10px !important;
}
.highlight{
  box-shadow: 0 4px 0  red;
}
.down-tree{
height: 90%;
 display: block;
 overflow-y: scroll;
}
</style>
